import axios from 'axios';
const baseURL = "https://api.imbydev.com/api"
import commonService from './commonFunction.js'
import AuthService from './AuthService.js';
import router from '../router/index.js'
export default{
    login(data){
        return axios.put(`${baseURL}/signin`,data)
    },
    forgot(data){
        return axios.post(`${baseURL}/recovery/`,data)
    },
    resetPassword(data){
        return axios.post(`${baseURL}/resetPassword`,data)
    },
    updateProfile(data){
      return axios.post(`${baseURL}/updateprofile`,data)
    },
    getUsers(data){
      return axios.get(`${baseURL}/user`,data)
    },
    updateUser(data,id){
      return axios.put(`${baseURL}/user/${id}`,data)
    },
    addUser(data){
      return axios.post(`${baseURL}/user`,data)
    },
    deleteUser(id){
      return axios.delete(`${baseURL}/user/${id}`)
    },
    getStates(data){
      return axios.get(`${baseURL}/state`,data)
    },
    updateState(data,id){
      return axios.put(`${baseURL}/state/${id}`,data)
    },
    addState(data){
      return axios.post(`${baseURL}/state`,data)
    },
    deleteState(id){
      return axios.delete(`${baseURL}/state/${id}`)
    },
    getRegion(data){
      return axios.get(`${baseURL}/region`,data)
    },
    updateRegion(data,id){
      return axios.put(`${baseURL}/region/${id}`,data)
    },
    addRegion(data){
      return axios.post(`${baseURL}/region`,data)
    },
    deleteRegion(id){
      return axios.delete(`${baseURL}/region/${id}`)
    },
    getCities(data){
      return axios.get(`${baseURL}/city`,data)
    },
    updateCity(data,id){
      return axios.put(`${baseURL}/city/${id}`,data)
    },
    addCity(data){
      return axios.post(`${baseURL}/city`,data)
    },
    deleteCity(id){
      return axios.delete(`${baseURL}/city/${id}`)
    },
    getCommunties(data){
      return axios.get(`${baseURL}/community`,data)
    },
    updateCommunity(data,id){
      return axios.put(`${baseURL}/community/${id}`,data)
    },
    addCommunity(data){
      return axios.post(`${baseURL}/community`,data)
    },
    deleteCommunity(id){
      return axios.delete(`${baseURL}/community/${id}`)
    }
}

axios.interceptors.request.use((reqConfig) => {
    let requestConfiguration = { ...reqConfig}
    let isLoggedIn = AuthService.isLoggedIn();
    if(isLoggedIn){
      let token = AuthService.getToken();
        requestConfiguration.headers['Authorization'] = 'Bearer '+ token
    }
    return requestConfiguration
  }
); 

axios.interceptors.response.use((response) => {
    if(response && response.data){
        return response.data
    }
    return response
  }, (error) => {
    if (error.response || error.request) {
      const data = error.response || error.request
      switch (data.status) {
        case 400 :
            commonService.alert(data.data.message,'Oops...','error',{icon: 'error',})
          break
        case   409:
          commonService.alert(data.data.message,'Oops...','error',{icon: 'error',})
        break
        case 401 :
            commonService.alert(data.data.message,'Oops...','error',{icon: 'error',})
            AuthService.logout()
            router.push({name:'login'})
          break
        case 422 :
          
        break
        case 460 :
          
        break
        case 500 :
          commonService.alert('something went wrong, Please try again','Oops...','error',{icon: 'error',})
        break
      }
    }
    return Promise.reject(error)
  })