import VueSimpleAlert from "vue-simple-alert";
export default{
    alert(message='',title='',type='success',options={icon:'success'}){
        VueSimpleAlert.alert(
            message,
            title,
            type,
            options
        )
    },
    calculateTop (id,listData) {
        if(listData.length<5) return false
        return (
          listData.map(function (x) {
          return x.id
        }).indexOf(id) > listData.length-3)
      },
      enableAttach (id, listData){
        if(listData.length<5) return false
        return (
          listData.map(function (x) {
          return x.id
        }).indexOf(id) < listData.length-4)
      },
}